export const presList= [
    {
        "title": "Chapter 13 Presentation",
        "chapter_index":"Chapter 13",
        "root_name": "presentation13.pdf",
        "start_page": "1",
        "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation13`,
        "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation13.pptx`,
        "length": "32"
    },
    {
        "title": "Chapter 14 Presentation",
        "chapter_index":"Chapter 14",
        "root_name": "presentation14.pdf",
        "start_page": "1",
        "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation14`,
        "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation14.pptx`,
        "length": "32"
    },
    {
        "title": "Chapter 15 Presentation",
        "chapter_index":"Chapter 15",
        "root_name": "presentation15.pdf",
        "start_page": "1",
        "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation15`,
        "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation15.pptx`,
        "length": "32"
    },
    {
        "title": "Chapter 16 Presentation",
        "chapter_index":"Chapter 16",
        "root_name": "presentation16.pdf",
        "start_page": "1",
        "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation16`,
        "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation16.pptx`,
        "length": "32"
    },
    {
        "title": "Chapter 17 Presentation",
        "chapter_index":"Chapter 17",
        "root_name": "presentation17.pdf",
        "start_page": "1",
        "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation17`,
        "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation17.pptx`,
        "length": "32"
    },
	{

    "title": "Chapter 18 Presentation",
    "chapter_index":"Chapter 18",
    "root_name": "presentation18.pdf",
    "start_page": "1",
    "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation18`,
	"download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation18.pptx`, 
    "length": "32"
},
    {
        "title": "Chapter 19 Presentation",
        "chapter_index":"Chapter 19",
        "root_name": "documents/presentation19.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/presentations/presentation19`,
	            "download_url": `${process.env.REACT_APP_DOCUMENT_CDN_URL}/powerpoints/presentation19.pptx`,
        "length": "34"
    }
];

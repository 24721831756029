import React, {useState, useContext} from 'react';
import {Accordion, Card, Button} from 'react-bootstrap';
import {UserContext} from '../../App';
import {titles_map} from "../utils/titles_map";
import {rcList} from "../utils/rc_map";
import {tgList} from "../utils/tg_map";
import {presList} from "../utils/pres_map";

const Course = ({title, testEnabled}) => {
    const [open, setOpen] = useState(false);
    const {loggedInUser} = useContext(UserContext);
    const handleToggle = () => setOpen(!open);


    let displayObjectChapterIndex = -1;
    const displayChapter = titles_map.find((item, index) => {
        if (item.root_name === title) {
            displayObjectChapterIndex = index;
            return true;
        }
        return false;
    });

    const displayRevisionCard = rcList[displayObjectChapterIndex];
    //adding the offset because there are the introduction materials
    const displayTestGateway = tgList[displayObjectChapterIndex + 5];
    return (
        <>
            <h3>🏛️ {displayChapter.title}</h3>
            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} varianut="link" eventKey="0" onClick={handleToggle}>
                            {open ? '▲' : '▼'} <span>{displayChapter.chapter_index} resources </span>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <a href={displayChapter.url}>📘 {displayChapter.title}</a><br/>
                            <a href={displayRevisionCard.url}>📓 {displayRevisionCard.title}</a><br/>
                             {(//e urat, stiu, da chiar n are rost sa mi bat creieru pt cv mai bun.
                                displayObjectChapterIndex + 1 === 13 || 
                                displayObjectChapterIndex + 1 === 14 || 
                                displayObjectChapterIndex + 1 === 15 || 
                                displayObjectChapterIndex + 1 === 16 ||
                                displayObjectChapterIndex + 1 === 17 || 
                                displayObjectChapterIndex + 1 === 18 || displayObjectChapterIndex + 1 === 19) &&
                                <a href={presList[(displayObjectChapterIndex + 1)-13].url}>📄 Presentation
                                    for {displayChapter.chapter_index} </a>} 
                            {(
                                
                                displayObjectChapterIndex + 1 === 13 || 
                                displayObjectChapterIndex + 1 === 14 || 
                                displayObjectChapterIndex + 1 === 15 || 
                                displayObjectChapterIndex + 1 === 16 ||
                                displayObjectChapterIndex + 1 === 17 || 
                                displayObjectChapterIndex + 1 === 18 || displayObjectChapterIndex + 1 === 19) &&
                                <a href={presList[(displayObjectChapterIndex + 1)-13].download_url} style={{ color: '#000065', display: 'inline' }}> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
      </svg> Download PPTX File</a> } <br/>
                            {testEnabled && <a href={`/chapterTest/${displayObjectChapterIndex + 1}`}>📝 Test
                                for {displayChapter.chapter_index}</a>}<br/>
                            {loggedInUser.role === 'tutor' &&
                                <>
                                    <a href={displayTestGateway.url}>👩‍🏫 {displayTestGateway.title}</a><br/>
                                    <a href={`/testResults/${displayObjectChapterIndex + 1}`}>✔️ Test Results</a><br/>
                                    <a href={`/testStatistics/${displayObjectChapterIndex + 1}`}>📊 Test
                                        Statistics</a><br/>
                                </>
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
};

export default Course;
